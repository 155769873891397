html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

@font-face {
  font-family: Tommy;
  src: url(assets/fonts/MADE\ TOMMY\ Light_PERSONAL\ USE.otf);
}

@font-face {
  font-family: Tommy;
  src: url(assets/fonts/MADE\ TOMMY\ Regular_PERSONAL\ USE.otf);
  font-weight: bold;
}

@font-face {
  font-family: Tommy;
  src: url(assets/fonts/MADE\ TOMMY\ Medium_PERSONAL\ USE.otf);
  font-weight: 900;
}

body {
  line-height: 1;
  font-family: 'Tommy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--terciary-color-light);

  --primary-color: #478dd1;
  --primary-color-light: #88accf;
  --secondary-color: #ee2c3e;
  --secondary-color-light: #ec7b86;
  --terciary-color: #bfc6cd;
  --terciary-color-light: #ebe9e4;
  --text-color: #35383b;
  --text-color-light: #7e8083;

  --master-content-width: 1200px;
}

button {
  background-color: unset;
  border: none;
  outline: none;
  user-select: none;
  font-family: inherit;
}

input, textarea, select {
  outline: none;
  font-family: inherit;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
